import api from '../../../api/api'

export default {
  dashboard: async (
    DataInicio,
    DataFim,
    producer,
    farm,
    agroindustry,
    consultant,
    reportId,
    activeProducers,
  ) => {
    const result = await api.get.getDashboardEducampo(
      DataInicio,
      DataFim,
      producer,
      farm,
      agroindustry,
      consultant,
      reportId,
      activeProducers,
    )

    return result
  },
}
