<template>
  <v-row justify="center">
    <v-dialog v-model="show" max-width="800">
      <v-card>
        <base-loading v-if="loading.loading" />

        <v-form
          v-else
          id="form"
          ref="form"
          v-model="valid"
          enctype="multipart/form-data"
          lazy-validation
          @submit.prevent="updateGestor"
        >
          <v-card-title>
            <span class="text-h5">Configuração da declaração</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <vuetify-money
                    v-model="form.custoFixo"
                    name="custoFixo"
                    label="Custo fixo (R$) *"
                    :options="options"
                    :required="true"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <vuetify-money
                    v-model="form.custokm"
                    name="custokm"
                    label="Custo/KM (R$) * "
                    :options="options"
                    :required="true"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model="form.kmpercorridos"
                    name="kmpercorridos"
                    label="Kilometragem rodada *"
                    type="number"
                    :required="true"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model="form.producaoMensal"
                    name="producaoMensal"
                    label="Produção mensal *"
                    type="number"
                    :required="true"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model="form.procentagemSobreProducao"
                    name="procentagemSobreProducao"
                    placeholder="00"
                    label="% sobre a produção (%) *"
                    :required="true"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <vuetify-money
                    v-model="form.valorTotalDaVisita"
                    name="valorTotalDaVisita"
                    label="Preço Total da visita (R$) *"
                    :options="{ ...options, precision: 2 }"
                    :disabled="true"
                    :required="true"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model="form.pagoPeloProdutor"
                    name="pagoPeloProdutor"
                    label="% Pago pelo produtor (%) *"
                    :required="true"
                    :rules="requiredRules"
                    :disabled="form.calculo === 'custoProdutor'"
                    @change="setProdutorEVigor"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <money-format
                    v-model="form.outrosValores"
                    name="outrosValores"
                    label="Outros valores (R$) *"
                    :options="options"
                    :required="true"
                    :rules="requiredRules"
                    :on-change="otherValues"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model="form.descricaoOutrosValoes"
                    name="descricaoOutrosValoes"
                    label="Descrição dos demais pagamentos realizados *"
                    :required="true"
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>

                <v-col cols="4" sm="4" md="4">
                  <money-format
                    v-model="form.CustoVisitaProdutor"
                    name="CustoVisitaProdutor"
                    label="Custo da visita para o produtor (R$) *"
                    :options="{ ...options, precision: 2 }"
                    :rules="requiredRules"
                    :on-change="setPercentageValue"
                    :disabled="form.calculo === 'percentageProdutor'"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <money-format
                    v-model="form.custoVisitaVigor"
                    name="custoVisitaVigor"
                    label="Custo da visita para Vigor (R$) *"
                    :options="{ ...options, precision: 2 }"
                    :rules="requiredRules"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="5" sm="5" md="5">
                  <v-container fluid>
                    <p>Custos pagos por:</p>
                    <v-radio-group
                      v-model="form.desconto"
                      :required="true"
                      :rules="requiredRules"
                      inline
                      @change="choseValues"
                    >
                      <v-radio
                        label="Pago pela vigor *"
                        value="vigor"
                      ></v-radio>
                      <v-radio
                        label="Pago pelo produtor"
                        value="produtor"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-col>
                <v-col cols="5" sm="5" md="5">
                  <v-container fluid>
                    <p>Calcular por:</p>
                    <v-radio-group
                      v-model="form.calculo"
                      :required="true"
                      :rules="requiredRules"
                      inline
                    >
                      <v-radio
                        label="Custo do produtor"
                        value="custoProdutor"
                      ></v-radio>
                      <v-radio
                        label="% do produtor"
                        value="percentageProdutor"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
            <small>* indica campo obrigatório</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close"> Cancelar </v-btn>
            <v-btn
              color="green darken-1"
              text
              :loading="loading.save"
              :disabled="loading.save"
              @click="update"
            >
              Atualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import MoneyFormat from '../../../components/base/MoneyFormat.vue'
import ReportService from '../../../services/api/reports'

export default {
  components: {
    MoneyFormat,
  },
  props: {
    value: Boolean,

    relatorio: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
      valid: true,

      finalizedCpp: '',

      requiredRules: [v => !!v || '* Obrigatório'],

      form: {
        custoFixo: '',
        custokm: '',
        kmpercorridos: '',
        producaoMensal: '',
        procentagemSobreProducao: '',
        valorTotalDaVisita: '',
        pagoPeloProdutor: '',
        outrosValores: '',
        descricaoOutrosValoes: '',
        CustoVisitaProdutor: '',
        custoVisitaVigor: '',
        desconto: '',
        calculo: 'custoProdutor',
      },

      backup: {},

      options: {
        locale: 'pt-BR',
        prefix: 'R$',
        suffix: '',
        length: 14,
        precision: 2,
      },

      loading: {
        save: false,
        loading: true,
      },
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  watch: {
    // whatch form
    form: {
      handler() {
        const valorTotalVisita = this.valorTotalDaVisita(this.form)
        this.form.valorTotalDaVisita = valorTotalVisita
      },
      deep: true,
    },
  },

  mounted() {
    this.load()
  },

  methods: {
    async update() {
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        return this.Toast().fire({
          icon: 'error',
          title: 'Preencha todos os campos obrigatórios!',
        })
      }

      this.loading.save = true

      try {
        const fields = Object.entries(this.form).map(([key, value]) => ({
          key,
          value,
        }))

        fields.push({
          key: 'porcentagemcustoProdutor',
          value: this.parsedPercentege(this.form.pagoPeloProdutor),
        })

        fields.push({
          key: 'porcentagemcustoVisitaVigor',
          value: this.form.pagoPeloProdutor
            ? 100 - this.parsedPercentege(this.form.pagoPeloProdutor)
            : 0,
        })

        const result = await ReportService.updateFields({
          id: this.relatorio.CodRelatorio,
          fields,
        })

        if (result.success) {
          this.Toast().fire({
            icon: 'success',
            title: 'Dados atualizados com sucesso!',
          })
        } else {
          throw new Error('Não foi possível atualizar os dados!')
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: `Error ao atualizar os dados: ${JSON.stringify(error)}`,
        })
      } finally {
        this.close()
      }
    },

    async load() {
      try {
        const response = await ReportService.getFields({
          id: this.relatorio.CodRelatorio,
          fields: Object.keys(this.form),
        })

        if (response.success) {
          const form = {}

          for (const item of response.data) {
            if (typeof item['value'] === 'string') {
              if (
                item['value'].indexOf('.') &&
                item['value'].indexOf(',') > -1
              ) {
                const [integer] = item['value'].split(',')
                item['value'] = integer.replace('.', '')
              }
            }

            form[item['key']] = item['value']
          }

          if (!form.calculo) {
            form.calculo = 'custoProdutor'
          }

          this.form = form
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Não foi possível carregar os dados do relatório',
        })
      } finally {
        this.loading.loading = false
      }
    },

    valorTotalDaVisita(form) {
      if (
        !form.custoFixo ||
        !form.kmpercorridos ||
        !form.custokm ||
        !form.producaoMensal ||
        !form.procentagemSobreProducao
      ) {
        return ''
      }

      if (this.parsedPercentege(form.procentagemSobreProducao) > 100) {
        return this.Toast().fire({
          icon: 'error',
          title: 'A porcentagem não pode ser maior que 100%',
        })
      }

      const custoFixo =
        parseFloat(form.custoFixo) +
        parseFloat(form.kmpercorridos) * parseFloat(form.custokm) +
        (parseFloat(form.producaoMensal) *
          this.parsedPercentege(form.procentagemSobreProducao)) /
          100

      return custoFixo
    },

    choseValues() {
      if (this.form.desconto === 'vigor') {
        this.form.custoVisitaVigor =
          this.getValueByType('vigor') + this.getOutrosValores()
      } else {
        this.form.custoVisitaVigor = this.getValueByType('vigor')
      }

      if (this.form.desconto === 'produtor') {
        this.form.CustoVisitaProdutor =
          this.getValueByType('produtor') + this.getOutrosValores()
      } else {
        this.form.CustoVisitaProdutor = this.getValueByType('produtor')
      }
    },

    getOutrosValores() {
      if (!this.form.outrosValores) return 0

      return this.parsedMoney(this.form.outrosValores)
    },

    setPercentageValue() {
      const valueProdutor = this.parsedMoney(this.form.CustoVisitaProdutor)

      if (this.form.CustoVisitaProdutor) {
        this.form.custoVisitaVigor = this.getValueByType('vigor')
      }

      if (this.form.custoVisitaVigor && this.form.CustoVisitaProdutor) {
        const valorTotal = this.parsedMoney(this.form.valorTotalDaVisita)

        const percentege = (valueProdutor * 100) / valorTotal
        this.form.pagoPeloProdutor = String(percentege.toFixed(3)).replace(
          '.',
          ',',
        )

        this.otherValues()
      }

      if (!this.validateCustos()) return
    },

    validateCustos() {
      let custoVisitaVigor = this.getValueByType('vigor')
      let custoVisitaProdutor = this.getValueByType('produtor')
      let outrosValores = this.form.desconto ? this.getOutrosValores() : 0

      if (custoVisitaVigor < 0) custoVisitaVigor = 0

      const somaDeValores = Math.abs(
        parseFloat(custoVisitaProdutor + custoVisitaVigor - outrosValores),
      )

      if (somaDeValores > this.parsedMoney(this.form.valorTotalDaVisita)) {
        this.form.pagoPeloProdutor = ''
        this.form.custoVisitaVigor = ''
        this.form.CustoVisitaProdutor = ''

        this.Toast().fire({
          icon: 'error',
          title:
            'A soma dos valores não pode ser maior que o valor total da visita',
        })

        return false
      }

      return true
    },

    setProdutorEVigor() {
      this.custoVisitaProdutor = ''
      this.custoVisitaVigor = ''

      if (this.form.pagoPeloProdutor && this.form.valorTotalDaVisita) {
        this.form.CustoVisitaProdutor = this.getValueByType('produtor')

        this.form.custoVisitaVigor = this.getValueByType('vigor')

        this.form.desconto = ''
        this.lastChose = ''
      }
    },

    otherValues() {
      if (!this.form.outrosValores) {
        return
      }

      if (this.form.desconto === 'vigor') {
        this.form.custoVisitaVigor =
          this.getValueByType('vigor') + this.getOutrosValores()
      }
      if (this.form.desconto === 'produtor') {
        this.form.CustoVisitaProdutor =
          this.getValueByType('produtor') + this.getOutrosValores()
      }
    },

    getValueByType(type) {
      const valorTotal = this.parsedMoney(this.form.valorTotalDaVisita)
      let percentage = this.parsedPercentege(this.form.pagoPeloProdutor)

      if (type === 'vigor') {
        if (this.form.calculo === 'custoProdutor') {
          const valorProdutor = this.parsedMoney(this.form.CustoVisitaProdutor)

          return valorTotal - valorProdutor
        }

        return (valorTotal * (100 - percentage)) / 100
      }

      if (type === 'produtor') {
        if (this.form.calculo === 'custoProdutor') {
          const valorProdutor = this.parsedMoney(this.form.CustoVisitaProdutor)

          return valorProdutor
        }

        return (valorTotal * percentage) / 100
      }

      this.lastChose = type
    },

    valorVisitaProdutor(form) {
      if (!form.pagoPeloProdutor) {
        return this.parsedMoney(form.CustoVisitaProdutor) || 0
      }
      return this.parsedMoney(form.CustoVisitaProdutor)
    },

    valorVisitaVigor(form) {
      if (!form.pagoPeloProdutor) {
        return this.parsedMoney(form.custoVisitaVigor) || 0
      }

      return this.parsedMoney(form.custoVisitaVigor)
    },

    parsedMoney(value) {
      if (typeof value === 'number') return value

      value = value.replace(',', '.')
      return parseFloat(parseFloat(value?.replace(/[^0-9.-]+/g, '')))
    },

    parsedPercentege(value) {
      if (typeof value === 'number') return value

      const parsed = value.replace(',', '.')
      return Number(parsed.replace(/[^0-9.-]+/g, ''))
    },

    close() {
      this.show = false
      this.loading.save = false
      this.finalizedCpp = ''
    },
  },
}
</script>
